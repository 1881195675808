<template>
  <div class="van">
    <div class="title">
      <div class="title-left">VANCHEEN {{ $t.meta.storeroom }}</div>
      <div class="title-right" @click="search">{{ $t.meta.more }}</div>
    </div>

    <div class="van-content">
      <div
        class="item"
        v-for="(item, index) in ListProduct"
        v-bind:key="index"
        @click="godetail(item.ProductNumber)"
      >
        <div class="item-header">
          <van-image
            class="shopImg"
            :src="item.ProductPhoto && imgUrlFilter(item.ProductPhoto)"
          >
            <template v-slot:error>
              <img class="ImgIcon" src="~assets/img/other/empty.gif" />
            </template>
          </van-image>

          <!-- <img
            :src="item.ProductPhoto && imgUrlFilter(item.ProductPhoto)"
            alt
          />-->
          <div class="item-img-top" v-if="isShowTop">
            {{ $t.meta.preferred }}
          </div>
          <div class="item-img-bot" v-if="isShowBot">
            <div class="bot-top-item">
              <div>{{ $t.meta.discount }}</div>
              <div class="item-last">{{ $t.meta.minSpend }}</div>
            </div>
            <div class="bot-bot-item">
              <div class="a">{{ item.MinPrice }}</div>
            </div>
          </div>
        </div>
        <div class="item-main">
          <div class="item-main-title">
            <div class="discount" v-if="item.IsCouponProduct">
              {{ $t.discount }}
            </div>
            <div class="brand" v-if="item.IsBrandProduct">
              {{ $t.brand }}
            </div>
            <div class="attestation" v-if="item.IsAttestationProduct">
              {{ $t.attestation }}
            </div>
            <div class="name">{{ item.ProductName }}</div>
          </div>
          <div class="item-main-dec">
            <div class="usd">USD&nbsp;{{ item.MinPrice }}</div>
            <!-- <div>{{ item.Sales }}sold</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Image } from "vant";
Vue.use(Image);

export default {
  name: "VanCheen",
  props: ["ListProduct"],
  data() {
    return {
      isShowTop: true,
      isShowBot: true,
    };
  },
  methods: {
    search() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "search",
        name: "search",
        query: [],
      });
    },
    imgUrlFilter(value) {
      //console.log(value.split("|")[0]);
      return this.$api.formatImageUrl(value.split("|")[0]);
    },
    godetail(ProductNumber) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "details",
        name: "details",
        query: [
          {
            name: "ProductNumber",
            value: ProductNumber,
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.van {
  margin-top: 0px;
  // box-shadow: rgb(189, 188, 188) 0px 0px 20px;
  padding: 10px;
  box-sizing: border-box;
  .title {
    padding: 0;
  }
  .van-list__loading {
    padding: 0% 0 10% 0 !important;
  }
  &-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    .item {
      width: 49%;
      margin-top: 2vw;
      background: #fff;
      padding: 0;
      box-sizing: border-box;
      .item-header {
        position: relative;
        height: 49.5vw;

        .shopImg {
          width: 100%;
          height: 100%;
          object-fit: cover;
          .ImgIcon {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
          ::v-deep .van-image__img {
            height: 100%;
            width: 100%;
            position: relative;
            object-fit: cover;
          }
          ::v-deep .van-image__error {
            height: 100%;
            width: 100%;
            position: relative;
            object-fit: cover;
          }
        }
        .item-img-top {
          color: #fff;
          background-color: #03ac0e;
          position: absolute;
          top: 0;
          left: 0;
          font-size: 12px;
          padding: 5px;
        }
        .item-img-bot {
          color: #fff;
          background-color: #03ac0e;
          position: absolute;
          left: 0;
          bottom: 0;
          display: flex;
          align-items: flex-end;
          font-weight: bolder;
          padding: 3px 5px;
          .bot-top-item {
            font-size: 10px;
            .item-last {
              color: #f0e64c;
            }
          }
          .bot-bot-item {
            font-size: 18px;
            .a {
              margin-left: 5px;
            }
          }
        }
      }
      &-main {
        padding: 10px;
        display: flex;
        justify-content: space-around;
        -webkit-justify-content: space-around;
        align-items: center;
        flex-direction: column;
        padding-bottom: 0;
        &-title {
          .discount {
            vertical-align: middle;
            display: inline-block;
            background: red;
            color: white;
            padding: 0px 2px;
            border-radius: 2px;
            font-size: 10px;
            margin-right: 5px;
          }
          .brand {
            vertical-align: middle;
            display: inline-block;
            background: #e6a23c;
            color: white;
            padding: 0px 2px;
            border-radius: 2px;
            font-size: 10px;
            margin-right: 5px;
          }
          .attestation {
            vertical-align: middle;
            display: inline-block;
            background: #22b196;
            color: white;
            padding: 0px 2px;
            border-radius: 2px;
            font-size: 10px;
            margin-right: 5px;
          }
          .name {
            display: inline-block;
            vertical-align: middle;
          }
          width: 100%;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-size: 14px;
          min-height: 50px;
        }
        &-dec {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 20px;
          font-size: 14px;
          padding-bottom: 10px;
          box-sizing: border-box;
          .usd {
            color: #03ac0e;
          }
        }
      }
    }
  }
}
.title {
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  font-size: 14px;
  padding: 15px 20px;
  align-items: center;
  &-left {
    color: #000;
    font-weight: bold;
  }
  &-right {
    color: #3fc21b;
    font-size: 14px;
  }
}
</style>
